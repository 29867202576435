import { render, staticRenderFns } from "./WelcomeCard.vue?vue&type=template&id=59baa23d&scoped=true&"
import script from "./WelcomeCard.vue?vue&type=script&lang=ts&"
export * from "./WelcomeCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59baa23d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/tmp/build_ae374436/src/components/Logo.vue').default,ProductTitle: require('/tmp/build_ae374436/src/components/ProductTitle.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle})
